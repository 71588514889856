import {
  InstallLocation,
  IntegrationApplication,
  WidgetId,
} from '@wix/members-area-app-definitions';
import { MY_ACCOUNT_PAGE_APP_DEF_ID, MY_ACCOUNT_PATH } from './widget';

export { GfppAction } from './gfpp';
export { CONTROLLER_PROP_CONFIG_KEY_ROUTES } from './controller';
export { ViewerPublicApiError } from './errors';
export { MenuId, ViewerMenuId } from './menus';
export { ReferralInfo, SlugPlaceholder, SLUG_PLACEHOLDERS } from './url';
export { MEMBER_TPA_PAGE_ID, MEMBER_SETTINGS_TPA_PAGE_ID } from './tpa';
export { Experiment } from './experiments';
export { ElementId } from './elements';
export { HistoryLabel } from './history';
export { DataHook } from './data-hook';

export {
  SlotsContainerState,
  SettingsSlotsContainerState,
} from './multi-state-box';

export {
  SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
  SETTINGS_SLOT_ROLE_ID_TO_MULTI_STATE_BOX_STATE,
} from './slot';

export {
  SETTINGS_PAGE_BOB_WIDGET_ID,
  PROFILE_PAGE_BOB_WIDGET_ID,
  PROFILE_PAGE_BOB_APP_DEF_ID,
  WidgetName,
  Layout,
  SettingsLayout,
  HostWidgetId,
  PROFILE_WIDGET_OPTIONS,
  SETTINGS_WIDGET_OPTIONS,
  MY_ACCOUNT_PATH,
  MY_ACCOUNT_PAGE_WIDGET_ID,
  MY_ACCOUNT_PAGE_APP_DEF_ID,
  APP_TOKEN,
  SANTA_MEMBERS_APP_DEF_ID,
  PROFILE_PAGE_BOB_STAGE_ID,
} from './widget';

export {
  COMPONENTS_REF,
  COMPONENTS_SECTION,
  COMPONENTS_SECTION_RESPONSIVE,
  HorizontalMenuRole,
  VERTICAL_LAYOUT_MENU_ROLE,
} from './components';

export const MY_ACCOUNT_DEFINITION: IntegrationApplication = {
  pageId: 'member_info',
  appDefinitionId: MY_ACCOUNT_PAGE_APP_DEF_ID,
  widgetId: WidgetId.MyAccount,
  urlOverride: MY_ACCOUNT_PATH,
  social: false,
  socialHome: true,
  installLocation: InstallLocation.Settings,
};

export const WARMUP_DATA_STATE_KEY = 'members-warmup-state';

export enum PanelOrigin {
  ProfilePage,
  SettingsPage,
  SinglePageApp,
}
